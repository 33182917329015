











import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Pagination from '@/core/support/pagination/Pagination';

@Component
export default class DataTableLimitSelect extends Vue {
  /**
   * Props
   */
  @Prop() private limit!: number;
  @Prop({default: false}) private disabled!: boolean;

  /**
   * Data
   */
  private options: number[] = [15, 30, 50, 100];

  /**
   * Get per page
   */
  private get limitModel(): number {
    return this.limit;
  }

  /**
   * Set per page
   */
  private set limitModel(limit: number) {
    this.$emit('update', limit);
  }
}
